import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8.061 3.23a1.2 1.2 0 0 1 1.697 0l6.52 6.52a3.186 3.186 0 0 1 0 4.497l-6.52 6.52a1.2 1.2 0 0 1-1.697-1.697l6.52-6.52a.786.786 0 0 0 0-1.103l-6.52-6.52a1.2 1.2 0 0 1 0-1.697",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }